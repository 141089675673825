<template>
    <div class="technical-details" v-show="hasLoaded">
        <card :icon="'pe-7s-like'" :headline="$t('visitors.checks.headline')" :description="$t('visitors.checks.description')">
            <ul class="main-list">
                <li v-if="mobileFriendly !== null">
                    <template v-if="mobileFriendly">
                        <i class="pe-7s-check" :style="positiveColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.mobileFriendly.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.mobileFriendly.positiveDescription')}}</p>
                        </div>
                    </template>

                    <template v-else>
                        <i class="pe-7s-close-circle danger" :style="negativeColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.mobileFriendly.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.mobileFriendly.negativeDescription')}}</p>
                        </div>
                    </template>
                </li>

                <li v-if="version && platform">
                    <template v-if="!threats">
                        <i class="pe-7s-check" :style="positiveColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.vulnerabilities.headline')}}</strong>
                            <p class="text-muted">
                                {{$t('visitors.checks.vulnerabilities.positiveDescription', {version: version, platform: platform})}}
                            </p>
                        </div>
                    </template>

                    <template v-else>
                        <i class="pe-7s-close-circle danger" :style="negativeColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.vulnerabilities.headline')}}</strong>
                            <p class="text-muted">
                                {{$t('visitors.checks.vulnerabilities.negativeDescription', {version: version, platform: platform, threats: threats})}}
                            </p>
                        </div>
                    </template>
                </li>

                <li v-if="usingSeoPlugin !== null">
                    <template v-if="usingSeoPlugin">
                        <i class="pe-7s-check" :style="positiveColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.seo.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.seo.positiveDescription')}}</p>
                        </div>
                    </template>

                    <template v-else>
                        <i class="pe-7s-close-circle danger" :style="negativeColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.seo.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.seo.negativeDescription')}}
                                <a data-toggle="modal" data-target="#seo-plugins" class="hidden-xs hidden-sm" v-if="platform === 'wordpress'">{{$t('visitors.checks.seeSolutions')}}</a>
                            </p>
                        </div>
                    </template>
                </li>

                <li v-if="speedScore !== null">
                    <template v-if="speedScore > 80">
                        <i class="pe-7s-check" :style="positiveColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.speedScore.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.speedScore.positiveDescription')}}</p>
                        </div>
                    </template>

                    <template v-else>
                        <i class="pe-7s-close-circle danger" :style="negativeColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.speedScore.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.speedScore.negativeDescription')}}</p>
                        </div>
                    </template>

                </li>

                <li v-if="isCacheEnabled !== null">
                    <template v-if="isCacheEnabled">
                        <i class="pe-7s-check" :style="positiveColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.cache.headline')}}</strong>
                            <p class="text-muted">{{$t('visitors.checks.cache.positiveDescription')}}</p>
                        </div>
                    </template>

                    <template v-else>
                        <i class="pe-7s-close-circle danger" :style="negativeColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.cache.headline')}}</strong>
                            <p class="text-muted">
                                {{$t('visitors.checks.cache.negativeDescription')}}
                                <a data-toggle="modal" data-target="#cache-plugins" class="hidden-xs hidden-sm" v-if="platform === 'wordpress'">
                                    {{$t('visitors.checks.seeSolutions')}}
                                </a>
                            </p>
                        </div>
                    </template>
                </li>

                <li v-if="isResourcesCompressed !== null">
                    <template v-if="isResourcesCompressed">
                        <i class="pe-7s-check" :style="positiveColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.compression.headline')}}</strong>
                            <p class="text-muted">
                                {{$t('visitors.checks.compression.positiveDescription')}}
                            </p>
                        </div>
                    </template>

                    <template v-else>
                        <i class="pe-7s-close-circle danger" :style="negativeColor"></i>

                        <div>
                            <strong>{{$t('visitors.checks.compression.headline')}}</strong>
                            <p class="text-muted">
                                {{$t('visitors.checks.compression.negativeDescription')}}
                                <a data-toggle="modal" data-target="#minification-plugins" class="hidden-xs hidden-sm" v-if="platform === 'wordpress'">{{$t('visitors.checks.seeSolutions')}}</a>
                            </p>
                        </div>
                    </template>
                </li>
            </ul>

            <div class="clearfix"></div>

            <!-- Cache Modal -->
            <div id="cache-plugins" class="modal fade modal-plugins" role="dialog">
                <div class="modal-dialog">

                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">{{$t('visitors.checks.cache.modal.headline')}}</h4>
                        </div>

                        <div class="modal-body">

                            {{$t('visitors.checks.cache.modal.description')}}

                            <hr>

                            <br>

                            <ul class="row">
                                <li class="col-md-6">
                                    <img src="/images/website/checks/w3-total-cache-256x256.png"/>
                                    <div>
                                        <strong>{{$t('visitors.checks.cache.modal.plugin1.headline')}}</strong><br>
                                        <small class="text-muted">{{$t('visitors.checks.cache.modal.plugin1.activeUsers')}}</small><br>
                                        <a href="https://da.wordpress.org/plugins/w3-total-cache/" target="_blank" rel="noreferrer noopener">
                                            {{$t('visitors.checks.goToPlugin')}}
                                        </a>
                                    </div>
                                </li>

                                <li class="col-md-6">
                                    <img src="/images/website/checks/wp-super-cache-256x256.png"/>
                                    <div>
                                        <strong>{{$t('visitors.checks.cache.modal.plugin2.headline')}}</strong><br>
                                        <small class="text-muted">{{$t('visitors.checks.cache.modal.plugin2.activeUsers')}}</small><br>
                                        <a href="https://da.wordpress.org/plugins/wp-super-cache/" target="_blank" rel="noreferrer noopener">
                                            {{$t('visitors.checks.goToPlugin')}}
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <br>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">{{$t('visitors.checks.close')}}</button>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Minification Modal -->
            <div id="minification-plugins" class="modal fade modal-plugins" role="dialog">
                <div class="modal-dialog">

                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">{{$t('visitors.checks.compression.modal.headline')}}</h4>
                        </div>

                        <div class="modal-body">

                            {{$t('visitors.checks.compression.modal.description')}}

                            <hr>

                            <br>

                            <ul class="row">
                                <li class="col-md-6">
                                    <img src="/images/website/checks/w3-total-cache-256x256.png"/>
                                    <div>
                                        <strong>{{$t('visitors.checks.compression.modal.plugin1.headline')}}</strong><br>
                                        <small class="text-muted">{{$t('visitors.checks.compression.modal.plugin2.activeUsers')}}</small><br>
                                        <a href="https://da.wordpress.org/plugins/w3-total-cache/" target="_blank" rel="noreferrer noopener">
                                            {{$t('visitors.checks.goToPlugin')}}
                                        </a>
                                    </div>
                                </li>

                                <li class="col-md-6">
                                    <img src="/images/website/checks/wp-fastest-cache-128x128.png"/>
                                    <div>
                                        <strong>{{$t('visitors.checks.compression.modal.plugin2.headline')}}</strong><br>
                                        <small class="text-muted">{{$t('visitors.checks.compression.modal.plugin2.activeUsers')}}</small><br>
                                        <a href="https://da.wordpress.org/plugins/wp-fastest-cache/" target="_blank" rel="noreferrer noopener">
                                            {{$t('visitors.checks.goToPlugin')}}
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <br>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">
                                {{$t('visitors.checks.close')}}
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <!-- SEO Modal -->
            <div id="seo-plugins" class="modal fade modal-plugins" role="dialog">
                <div class="modal-dialog">

                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                            <h4 class="modal-title">{{$t('visitors.checks.seo.modal.headline')}}</h4>
                        </div>

                        <div class="modal-body">

                            {{$t('visitors.checks.seo.modal.description')}}

                            <hr>

                            <br>

                            <ul class="row">
                                <li class="col-md-6">
                                    <img src="/images/website/checks/yoast-256x256.png"/>
                                    <div>
                                        <strong>{{$t('visitors.checks.seo.modal.plugin1.headline')}}</strong><br>
                                        <small class="text-muted">{{$t('visitors.checks.seo.modal.plugin1.activeUsers')}}</small><br>
                                        <a href="https://da.wordpress.org/plugins/wordpress-seo/" target="_blank" rel="noreferrer noopener">
                                            {{$t('visitors.checks.goToPlugin')}}
                                        </a>
                                    </div>
                                </li>

                                <li class="col-md-6">
                                    <img src="/images/website/checks/all-in-one-seo-pack-256x256.png"/>
                                    <div>
                                        <strong>{{$t('visitors.checks.seo.modal.plugin2.headline')}}</strong><br>
                                        <small class="text-muted">{{$t('visitors.checks.seo.modal.plugin2.description')}}</small><br>
                                        <a href="https://da.wordpress.org/plugins/all-in-one-seo-pack/" target="_blank" rel="noreferrer noopener">
                                            {{$t('visitors.checks.goToPlugin')}}
                                        </a>
                                    </div>
                                </li>
                            </ul>

                            <br>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal">
                                {{$t('visitors.checks.close')}}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </card>
    </div>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_vars.scss';

    .technical-details {
        ul.main-list {
            list-style: none;
            padding: 0;
            margin: 5px 0 0;
            display: flex;
            flex-wrap: wrap;

            li {
                position: relative;
                margin-bottom: 15px;
                min-height: 67px;
                flex: 0 0 50%;

                div {
                    margin-left: 55px;
                    padding-top: 5px;
                }

                i {
                    font-size: 40px;
                    margin-right: 15px;
                    color: #40d4b6;
                    position: absolute;
                    left: 5px;
                    top: 0;

                    &.danger {
                        color: #dd2d29;
                    }
                }
            }
        }

        .modal.modal-threats {
            ul {
                list-style: none;
                margin: 15px 0;
                padding: 0;
                font-size: 16px;

                li {
                    position: relative;
                    margin-bottom: 15px;

                    .threat {
                        width: 10px;
                        height: 10px;
                        border-radius: 99px;
                        background: #f5d000;
                        display: inline-block;
                        margin: 0 15px;
                        position: absolute;
                        left: 0px;
                        top: 6px;

                        &.high {
                            background: #dd2d29;
                        }
                    }

                    .text {
                        margin-left: 45px;
                        font-size: 16px;
                    }
                }
            }

            .well {
                margin: 0 15px;
            }
        }

        .modal.modal-plugins {
            font-size: 15px;

            hr {
                margin: 15px -15px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    height: 90px;

                    strong {
                        font-size: 16px;
                    }

                    img {
                        position:absolute;
                        left: 0;
                        top: 0;
                        width: 75px;
                    }

                    > div {
                        margin-left: 75px;
                    }
                }
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        .checks ul.tests li {
            flex: 0 0 100%;
        }
    }
</style>

<script>
    import Card from '@/app/shared/components/Card'

    const TechnicalDetailsService = require('@/services/technical-details/TechnicalDetailsService')
    const VulnerabilitiesService = require('@/services/technical-details/VulnerabilitiesService')

    import organization from '@/mixins/customer/organization'

    export default {
        mixins: [organization],

        data() {
            return {
                hasLoaded: false,
                platform: null,
                isCacheEnabled: null,
                isResourcesCompressed: null,
                mobileFriendly: null,
                speedScore: null,
                analyticsEnabled: null,
                usingSeoPlugin: null,
                threats: null,
                version: null,
                improvements: [
                    'isCacheEnabled',
                    'isResourcesCompressed',
                    'mobileFriendly',
                    'speedScore',
                    'analyticsEnabled',
                    'usingSeoPlugin'
                ]
            }
        },

        computed: {
            positiveColor() {
                return {color:$org('colors.standard.statuses.positive.focus')}
            },

            negativeColor() {
                return {color:$org('colors.standard.statuses.negative.focus')}
            }
        },

        mounted() {
            if (!this.isFreemium) {
                return
            }

            this.loadTechnicalDetails()
        },

        methods: {
            loadTechnicalDetails() {
                const options = {
                    limit: 1,
                    sort: [
                        {
                            key: 'created',
                            direction: 'DESC'
                        }
                    ]
                }

                TechnicalDetailsService.findAll(options, (response) => {
                    const body = response.data

                    if (!body.rows) {
                        return
                    }

                    const data = body.rows[0]

                    this.improvements.forEach(item => {
                        Object.entries(data).forEach(([key, value]) => {
                            if (key !== item || value === null) {
                                return
                            }

                            this[key] = value
                        })
                    })

                    this.hasLoaded = true

                    this.loadVulnerabilities()
                })
            },

            loadVulnerabilities() {
                if (!this.platform) {
                    return
                }

                if (!this.version) {
                    return
                }

                const options = {
                    filter_groups: [
                        {
                            filters: [
                                {
                                    key: 'version',
                                    value: this.version,
                                    operator: 'eq',
                                },
                                {
                                    key: 'platform',
                                    value: this.platform,
                                    operator: 'eq',
                                },
                            ]
                        }
                    ]
                }

                VulnerabilitiesService.findAll(options, (response) => {
                    const body = response.data

                    this.threats = body.total;
                })
            }
        },

        components: {
            Card
        },
    }
</script>
